import React, { useEffect } from 'react';

import { changeTypeEdl } from '../../api-service-customer';

const App = function (props) {
  useEffect(() => {
    changeTypeEdl();
    return () => {};
  }, []);

  return <>test</>;
};

export default App;
