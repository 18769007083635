import React, { useState, useRef, useMemo, useContext } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { AppContext } from '../../contexts-api/AppContextProvider';
import GoogleMap from './components/form/molecule/GoogleMap';
import ButtonGroupSky from './components/form/atom/ButtonGroupSky';
import TextFieldSky from './components/form/atom/TextFieldSky';
import InputGoogleSearch from './components/form/molecule/InputGoogleSearch';
import DateSky from './components/form/atom/DateSky';
import SelectSky from './components/form/atom/SelectSky';
import DialogSky from '../components/DialogSky';
import ViewEDL from '../components/ViewEDL';
import InputAdornment from '@mui/material/InputAdornment';

import SelectInfosAnnexes from './components/form/molecule/SelectInfosAnnexes';
import Tenants from './components/form/molecule/Tenants';

import getDefaultValues, { $NB_MAX_TENANTS, $NB_MAX_ANNEXES, $NB_MAX_FLOOR } from './defaultValue';

import { validationSchema } from './verificationEDL';
import { getDistance, calcPrice } from '../../api-service-common';
import { createEdl, deleteEdl } from '../../api-service-customer';
import { getGeo } from '../../utils';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';

const NewEDL = ({ edlToModify, setMessageInfos, handlePageChange }) => {
  const { profil } = useContext(AppContext);

  const defaultValues = useMemo(() => getDefaultValues(), []);
  const oldEdlToModify = useRef(null);
  const [datasForm, setDatasForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formIsSubmit, setFormIsSubmit] = useState(false);
  const typeHouse = useRef(defaultValues.possession.typeHouse);

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema(typeHouse.current),
    validate: (datasEdl) => {
      if (edlToModify?.metadatas) {
        delete datasEdl.metadatas;
        delete datasEdl.customer;
      }
      setFormIsSubmit(true);
      try {
        if (datasEdl.possession.address.region !== 'Île-de-France') {
          return {
            possession: {
              address: {
                street: 'Pas en Ile-de-France',
              },
            },
          };
        }
        validationSchema(typeHouse.current).validateSync(datasEdl);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    onSubmit: async (datasEdl, { setSubmitting }) => {
      try {
        setIsLoading(true);
        setSubmitting(true);
        const distance = await iGetDistance(datasEdl.possession.address.geopoint);
        const pricing = calcPrice(datasEdl, distance, profil.priceList);
        if (edlToModify?.metadatas?.isModifying)
          datasEdl['metadatas'] = {
            ...edlToModify.metadatas,
          };
        setDatasForm({
          ...datasEdl,
          ...{
            customer: {
              pricing: pricing,
              user: profil.user,
              company: profil.company,
            },
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const setTypeHouse = () => {
    typeHouse.current = formik.values.possession.typeHouse;
    let newEdl = null;
    if (formik.values.possession.typeHouse === 'parking') {
      newEdl = {
        ...formik.values,
        manage: {
          ...formik.values.manage,
          video: undefined,
        },
        possession: {
          ...formik.values.possession,
          annexes: undefined,
          area: undefined,
          numFloor: undefined,
          nbRoom: undefined,
          typeRent: undefined,
        },
      };
    } else {
      newEdl = {
        ...formik.values,
        manage: {
          ...formik.values.manage,
          video: defaultValues.manage.video,
        },
        possession: {
          ...formik.values.possession,
          annexes: defaultValues.possession.annexes,
          area: defaultValues.possession.area,
          nbRoom: defaultValues.possession.nbRoom,
          typeRent: defaultValues.possession.typeRent,
        },
      };
    }
    formik.setValues(newEdl, formIsSubmit);
  };

  const handleReset = () => {
    setFormIsSubmit(false);
    formik.handleReset();
  };

  const iGetDistance = async (geopoint) => {
    const company = profil.company;
    const companyGeo = getGeo(company.address.geopoint);
    const edlGeo = getGeo(geopoint);
    let gDistance = 0;
    if (process.env.REACT_APP_WITH_INTERNET === 'false') {
      gDistance = 4000;
    } else {
      gDistance = await getDistance(companyGeo, edlGeo);
    }
    return gDistance;
  };

  function handleCallbackAdress(results) {
    formik.setFieldValue(
      'possession.address',
      {
        street: results ? results.street.trim() : defaultValues.possession.address.street,
        complement: results ? formik.values.possession.address.complement : defaultValues.possession.address.complement,
        locality: results ? results.locality : defaultValues.possession.address.locality,
        zipCode: results ? results.zipCode : defaultValues.possession.address.zipCode,
        region: results ? results.administrative_area_level_1 : defaultValues.possession.address.region,
        geopoint: results ? results.geopoint : defaultValues.possession.address.geopoint,
      },
      formIsSubmit,
    );
  }

  const handleValidation = async ({ btn }) => {
    try {
      setIsLoading(true);
      const newEdl = cloneDeep(datasForm);
      if (['acceptCreateEdl', 'acceptModifiedEdl'].includes(btn)) {
        const pricingCustomer = newEdl.customer.pricing;
        let idEdl = edlToModify?.metadatas?.idEdl ?? false;
        delete newEdl.customer;
        delete newEdl.metadatas;
        await createEdl(idEdl, newEdl, profil.company.name, pricingCustomer);
        handlePageChange('Mes EDLs');
        handleReset();
        //await simuCreateEdl(datasForm);
      }
      if (btn === 'deleteModifiedEdl') {
        deleteEdl(newEdl.metadatas.idEdl, profil.company.name);
        handlePageChange('Mes EDLs');
        handleReset();
      }
    } catch (axios) {
      setMessageInfos((message) => [message[0] + 1, 'error', axios.response.data.error]);
    } finally {
      setIsLoading(false);
      setDatasForm({});
    }
  };

  /*const handleScrollToError = (errorFieldName) => {
    const errorField = document.getElementById(errorFieldName);
    if (errorField) {
      errorField.scrollIntoView({ behavior: "smooth" });
    }
  };*/

  if (typeHouse.current !== formik.values.possession.typeHouse) setTypeHouse();
  if (oldEdlToModify.current !== edlToModify) {
    oldEdlToModify.current = edlToModify;
    formik.setValues(cloneDeep(edlToModify), false);
  }
  return (
    <form>
      <Box sx={{ padding: 1 }}>
        <Grid container spacing={{ xs: 1, md: 2 }} disableEqualOverflow>
          <Grid xs={12}>
            <Typography variant="h6" color="primary">
              Créer un EDL
            </Typography>
            <Divider />
          </Grid>

          <Grid container>
            <Grid container direction="column" xs={12} md="auto">
              <Grid
                sx={{
                  marginBottom: '-5px',
                }}
              >
                <Typography variant="body">Date souhaitée</Typography>
              </Grid>
              <Grid md={'auto'}>
                <DateSky
                  required
                  name="manage.dateEDL"
                  value={formik.values.manage.dateEDL}
                  formik={formik}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
            </Grid>
            <Grid container direction="column">
              <Grid
                sx={{
                  marginBottom: '-5px',
                }}
              >
                <Typography variant="body">EDL</Typography>
              </Grid>
              <Grid md={'auto'}>
                <ButtonGroupSky
                  name="manage.typeEDL"
                  arrayValues={['entrant', 'sortant']}
                  value={formik.values.manage.typeEDL}
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid container direction="column">
              {formik.values.possession.typeHouse !== 'parking' && (
                <Grid container direction="column">
                  <Grid
                    sx={{
                      marginBottom: '-5px',
                    }}
                  >
                    <Typography variant="body">Vidéo</Typography>
                  </Grid>
                  <Grid md={'auto'}>
                    <ButtonGroupSky
                      name="manage.video"
                      arrayValues={['non', 'oui']}
                      value={formik.values.manage.video}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ marginBottom: '-5px' }}>
            <Typography variant="body">Adresse du bien</Typography>
          </Grid>
          <Grid xs={12} md={3}>
            <InputGoogleSearch
              name="possession.address.street"
              label="Rue et N° de rue"
              value={formik.values.possession.address.street}
              formik={formik}
              handleCallbackAdress={handleCallbackAdress}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <TextFieldSky
              name="possession.address.complement"
              label="Complément d'adresse (Bât, Appt, ...)"
              value={formik.values.possession.address.complement}
              formik={formik}
              formIsSubmit={formIsSubmit}
            />
          </Grid>
          <Grid xs={5} sm={4} md={2}>
            <TextFieldSky
              required
              disabled
              name="possession.address.zipCode"
              label="Code postal"
              value={formik.values.possession.address.zipCode}
              formik={formik}
              inputProps={{
                maxLength: 6,
                type: 'tel',
              }}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#1791D1',
                  backgroundColor: '#fafafa',
                },
              }}
              formIsSubmit={formIsSubmit}
            />
          </Grid>
          <Grid xs={7} sm={8} md={3}>
            <TextFieldSky
              required
              disabled
              name="possession.address.locality"
              label="Ville"
              value={formik.values.possession.address.locality}
              formik={formik}
              inputProps={{ maxLength: 41 }}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#1791D1',
                  backgroundColor: '#fafafa',
                },
              }}
              formIsSubmit={formIsSubmit}
            />
          </Grid>
          {formik.values.possession.address.geopoint && (
            <Grid
              md={12}
              display={{
                xs: 'none',
                sm: 'none',
                md: 'block',
              }}
            >
              {process.env.REACT_APP_WITH_INTERNET !== 'false' && (
                <GoogleMap center={formik.values.possession.address.geopoint} zoom="14" />
              )}
            </Grid>
          )}
          <Grid xs={12} md={12}>
            <Typography variant="body" component="div" sx={{ marginBottom: '-5px' }}>
              Informations sur le bien
            </Typography>
          </Grid>
          <Grid xs={12} md="auto">
            <TextFieldSky
              required
              name="possession.nameOwner"
              label="Nom du propriétaire"
              formik={formik}
              value={formik.values.possession.nameOwner}
              inputProps={{ maxLength: 41 }}
              formIsSubmit={formIsSubmit}
            />
          </Grid>
          <Grid xs="auto" md="auto">
            <ButtonGroupSky
              name="possession.typeHouse"
              arrayValues={['appartement', 'maison', 'parking']}
              formik={formik}
              value={formik.values.possession.typeHouse}
            />
          </Grid>
          {formik.values.possession.typeHouse !== 'parking' && (
            <>
              <Grid xs="auto">
                <ButtonGroupSky
                  name="possession.typeRent"
                  arrayValues={['nu', 'meublé']}
                  formik={formik}
                  value={formik.values.possession.typeRent}
                />
              </Grid>
              <div
                style={{
                  width: '100%',
                }}
              ></div>
              <Grid xs="auto">
                <SelectSky
                  name="possession.nbRoom"
                  label="Pièces"
                  options={['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8']}
                  value={formik.values.possession.nbRoom}
                  formik={formik}
                />
              </Grid>
              <Grid xs="auto">
                <TextFieldSky
                  name="possession.area"
                  label="Superficie"
                  formik={formik}
                  value={formik.values.possession.area}
                  sx={{
                    width: 120,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                    inputProps: {
                      min: 8,
                      max: 401,
                      type: 'number',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              <Grid xs="auto">
                <TextFieldSky
                  name="possession.numFloor"
                  label="Etage"
                  formik={formik}
                  value={formik.values.possession.numFloor}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: $NB_MAX_FLOOR,
                      type: 'number',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  formIsSubmit={formIsSubmit}
                />
              </Grid>
              <SelectInfosAnnexes
                formik={formik}
                formIsSubmit={formIsSubmit}
                $NB_MAX_ANNEXES={$NB_MAX_ANNEXES}
                defaultValues={formik.values.possession}
              />
            </>
          )}
          <Tenants
            name="tenants"
            defaultMaxTenants={$NB_MAX_TENANTS}
            value={formik.values.tenants}
            formik={formik}
            formIsSubmit={formIsSubmit}
          />
          <Grid xs={12}>
            <TextFieldSky
              name="grades"
              label="Informations complémentaires (Code, clés, autres...)"
              multiline
              inputProps={{ maxLength: 301 }}
              rows={4}
              formik={formik}
              value={formik.values.grades}
              formIsSubmit={formIsSubmit}
            />
          </Grid>

          <Grid xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid xs={12} sm={6} mdOffset={3} md={3}>
            <Button variant="outlined" onClick={handleReset} fullWidth disabled={isLoading}>
              Ré-initialiser
            </Button>
          </Grid>

          <Grid xs={12} sm={6} md={3}>
            <Button variant="contained" onClick={formik.handleSubmit} fullWidth disabled={isLoading}>
              validation
            </Button>
          </Grid>
          {Object.keys(datasForm).length !== 0 ? (
            <DialogSky
              open={true}
              handleDialog={handleValidation}
              status={datasForm?.metadatas?.isModifying ? 'modifiedEdlFromNewEdl' : 'creatingEdl'}
              isLoading={isLoading}
            >
              <ViewEDL datasEdl={datasForm} profil={profil} />
            </DialogSky>
          ) : null}
        </Grid>
      </Box>
    </form>
  );
};

export default NewEDL;
