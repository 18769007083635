import axios from 'axios';
import { getToken } from './api-service-common';

const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

export async function createEdl(idEdl, datasEdl, nameCompany, pricingCustomer) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/createEdl`;
    const res = await axios.post(url, {
      idEdl,
      datasEdl,
      nameCompany,
      pricingCustomer,
      token,
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteEdl(idEdl, nameCompanyCustomer) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/deleteEdl`;
    const res = await axios.post(url, { idEdl, nameCompanyCustomer, token });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function modifyEdl(idEdl, nameCompanyCustomer) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/modifyEdl`;
    const res = await axios.post(url, { idEdl, nameCompanyCustomer, token });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function acceptRefuseEdl(
  action,
  idEdl,
  nameCompanyCustomer,
  dateEDL,
  uidProvider,
  nameCompanyProvider,
  pricingProvider,
  motif,
) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/acceptRefuseEdl`;
    await axios.post(url, {
      action,
      idEdl,
      nameCompanyCustomer,
      dateEDL,
      uidProvider,
      nameCompanyProvider,
      pricingProvider,
      motif,
      token,
    });
  } catch (error) {
    console.error(error);
  }
}

export async function refuseEdlByAdmin(idEdl, nameCompanyCustomer, motif) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/refuseEdlByAdmin`;
    await axios.post(url, {
      idEdl,
      nameCompanyCustomer,
      motif,
      token,
    });
  } catch (error) {
    console.error(error);
  }
}

export async function acceptRefuseReportEdl(action, idEdl, nameCompanyCustomer) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/acceptRefuseReportEdl`;
    await axios.post(url, {
      action,
      idEdl,
      nameCompanyCustomer,
      token,
    });
  } catch (error) {
    console.error(error);
  }
}

export async function waitingEdl(idEdl, nameCompanyCustomer, uidProvider, nameCompanyProvider) {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/waitingEdl`;
    const res = await axios.post(url, {
      idEdl,
      nameCompanyCustomer,
      uidProvider,
      nameCompanyProvider,
      token,
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function changeTypeEdl() {
  try {
    const token = await getToken();
    const url = `${API_BASE_URL}/changeTypeEdl`;
    const res = await axios.post(url, {
      token,
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

function generateRandomLastName() {
  const nomsDeFamille = [
    'Martin',
    'Bernard',
    'Thomas',
    'Dubois',
    'Durand',
    'Roux',
    'Lefevre',
    'Leroy',
    'Moreau',
    'Simon',
    'Laurent',
    'Michel',
    'Girard',
    'Dupont',
    'Lefebvre',
    'Fontaine',
    'Rousseau',
    'Vincent',
    'Fournier',
    'Andre',
    'Mercier',
    'Blanc',
    'Guillaume',
    'Lucas',
    'Robin',
    'Meunier',
    'Faure',
    'Lambert',
    'Garnier',
    'Dumas',
    'Masson',
    'Garcia',
    'Bonnet',
    'Chevalier',
    'Vidal',
    'Gautier',
    'Muller',
    'Henry',
    'Roussel',
    'Nicolas',
    'Perrin',
    'Morin',
    'Mathieu',
    'Giraud',
    'Joly',
    'Riviere',
    'Leclerc',
    'Gonzalez',
    'Lemoine',
    'Picard',
    'Collin',
    'Dubois',
    'Remy',
  ];
  const randomIndex = Math.floor(Math.random() * nomsDeFamille.length);
  return nomsDeFamille[randomIndex];
}

function generateRandomCivility() {
  const civility = ['Mme', 'M.'];
  const randomIndex = Math.floor(Math.random() * civility.length);
  return civility[randomIndex];
}

function generateRandomDate() {
  const start = new Date('2023-06-22T07:00:00Z'); // Début à 7h
  const end = new Date('2023-12-31T21:00:00Z'); // Fin à 21h
  // Calculer le timestamp aléatoire entre les dates de début et de fin
  const timestamp = start.getTime() + Math.random() * (end.getTime() - start.getTime());
  // Créer une nouvelle date à partir du timestamp
  const date = new Date(timestamp);
  // Définir les minutes et secondes à zéro
  date.setMinutes(0);
  date.setSeconds(0);
  // Retourner la date au format UTC
  return date.toUTCString();
}

export async function simuCreateEdl(datasEdl) {
  const token = await getToken();
  const url = `${API_BASE_URL}/createEdl`;
  const villes = ['Paris', 'Lyon', 'Marseille', 'Bordeaux', 'Lille'];
  const promises = [];
  for (let i = 0; i < 30; i++) {
    const finalDate = generateRandomDate();
    const surname = generateRandomLastName();
    const civility = generateRandomCivility();
    const villeChoisie = villes[Math.floor(Math.random() * villes.length)];
    datasEdl.manage.dateEDL = finalDate;
    datasEdl.possession.address.locality = villeChoisie;
    datasEdl.tenants[0].surname = surname.toUpperCase();
    datasEdl.tenants[0].civility = civility;
    promises.push(axios.post(url, { datasEdl, token }));
  }
  await Promise.all(promises);
  return 'ok';
}

// Générer un timestamp aléatoire entre le 1er janvier 2000 et aujourd'hui
