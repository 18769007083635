import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import { useDropzone } from 'react-dropzone';
import './dropzone.css';
import LinearProgress from '@mui/material/LinearProgress';
import { storage } from '../../config';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInfosAnnexes from '../newEDL/components/form/molecule/SelectInfosAnnexes';

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useTheme } from '@emotion/react';
import { Divider, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';

export default function DialogFinishEDL({ handleDialog, datasEdl, priceList }) {
  const [open, setOpen] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [clickButtonUpload, setClickButtonUpload] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [newPossession, setNewPossession] = React.useState(null);

  const handleClose = (btn) => {
    if (typeof btn === 'string') {
      setOpen(false);
      handleDialog({ btn, newPossession });
    }
  };

  const handleChange = (event) => {
    setNewPossession(null);
    setChecked(event.target.checked);
  };

  let hasAnnexesModified = false;
  const showDiffPriceAnnexes = () => {
    const diffAnnexes = {};
    const newPossessionAnnexes = newPossession.annexes;
    const edlAnnexes = datasEdl.possession.annexes;

    const diffNbRoom = newPossession.nbRoom !== datasEdl.possession.nbRoom;
    let newPriceNbRoom = 0;
    if (diffNbRoom) {
      hasAnnexesModified = true;
      const calculerPrixEdl = (donneesEdl) => {
        const { typeHouse, nbRoom, area } = donneesEdl;
        const { baseTarif, maxSuperficie, majoSuperficie } = priceList[typeHouse][nbRoom];

        const superficieExcedentaire = area - maxSuperficie;

        return baseTarif + Math.max(superficieExcedentaire, 0) * majoSuperficie;
      };

      const prixSuperficieEdl = calculerPrixEdl(datasEdl.possession);
      const nouveauPrixSuperficieEdl = calculerPrixEdl(newPossession);

      newPriceNbRoom = nouveauPrixSuperficieEdl - prixSuperficieEdl;
    }

    const calculateDifference = (key, label) => {
      const possessionValue = Array.isArray(newPossessionAnnexes[key])
        ? newPossessionAnnexes[key].length
        : newPossessionAnnexes[key];
      const edlValue = Array.isArray(edlAnnexes[key]) ? edlAnnexes[key].length : edlAnnexes[key];
      const diff = possessionValue - edlValue;

      if (diff !== 0) {
        hasAnnexesModified = true;
        diffAnnexes[label] = diff;
      } else {
        delete diffAnnexes[label];
      }
    };

    calculateDifference('caves', 'Caves');
    calculateDifference('parkings', 'Parkings');
    calculateDifference('nbBalcony', 'Balcons');
    calculateDifference('nbGarden', 'Jardins');
    calculateDifference('nbOther', 'Autres');

    if (diffNbRoom || Object.keys(diffAnnexes).length) {
      const title = (
        <Grid xs={12} key={0}>
          <Typography variant="body" color="primary">
            Modification de la tarification
          </Typography>
        </Grid>
      );

      const objectNewRoom = (
        <Grid xs={12} key={1} textAlign="left">
          <Typography variant="body">{`Changement du nombre de pièces : ${newPriceNbRoom} €`}</Typography>
        </Grid>
      );

      const objectNewAnnexes = Object.keys(diffAnnexes).map((item, idx) => {
        const amount = diffAnnexes[item] * priceList.annexe;
        return (
          <Grid xs={12} key={idx + 2} textAlign="left">
            <Typography variant="body">{`${item} : ${amount > 0 ? `+${amount}` : amount} €`}</Typography>
          </Grid>
        );
      });
      return diffNbRoom ? [title, objectNewRoom, ...objectNewAnnexes] : [title, ...objectNewAnnexes];
    }
    return [];
  };

  const diffAnnexes = newPossession ? showDiffPriceAnnexes() : null;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            minWidth: { xs: '100%', sm: '80%' },
            minHeight: { xs: '100%', sm: '80%' },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          Dépot du rapport EDL
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} textAlign={'center'}>
            <Grid xs={12}>
              <DropzoneUpload
                setIsButtonEnabled={setIsButtonEnabled}
                clickButtonUpload={clickButtonUpload}
                handleClose={handleClose}
                datasEdl={datasEdl}
              />
            </Grid>
            <Grid container xs={12} justifyContent={'center'}>
              <Grid xs={12}>
                <Switch checked={checked} onChange={handleChange} />
                <Typography variant="body">Corriger le nombre d'annexes</Typography>
              </Grid>
              {checked && (
                <>
                  <SelectInfosAnnexes
                    formik={null}
                    formIsSubmit={false}
                    $NB_MAX_ANNEXES={3}
                    defaultValues={datasEdl}
                    setNewPossession={setNewPossession}
                  />
                  {hasAnnexesModified && diffAnnexes}
                  {hasAnnexesModified && (
                    <>
                      <Divider color="red" />
                      <Grid xs={12} textAlign="left">
                        <Typography variant="body" color="primary">
                          Toute demande de modification sera controlée par nos services
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose('cancelFinishEdl')}>Annuler</Button>
          <Button onClick={(e) => setClickButtonUpload(true)} disabled={!isButtonEnabled} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const DropzoneUpload = ({ setIsButtonEnabled, clickButtonUpload, handleClose, datasEdl }) => {
  const theme = useTheme();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);
  const maxLength = 100;
  const maxSizeMo = 100;

  useEffect(() => {
    if (clickButtonUpload && file !== null) {
      const idEdl = datasEdl.metadatas.idEdl;
      const metadata = {
        contentType: 'application/pdf',
        customMetadata: {
          idEdl: idEdl,
          nameCompany: datasEdl.customer.company.name,
          nameReportOrigin: file.name,
          typeEDL: datasEdl.manage.typeEDL,
        },
      };

      const storageRef = ref(storage, 'tempReportEdls/' + idEdl);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
            default:
              break;
          }
        },
        () => {
          handleClose('uploadFinishEdl');
        },
      );
    }
  }, [clickButtonUpload, datasEdl.customer.company.name, datasEdl.metadatas.idEdl, file, handleClose]);

  const dropzoneStyles = {
    border: '2px dashed #1791D1',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '20px',
    cursor: 'pointer',
    width: '100%',
  };

  function bytesToMegabytes(bytes) {
    const megabytes = bytes / (1000 * 1000);
    return megabytes.toFixed(2);
  }

  function validatorDropzone(file) {
    if (file.name.length > maxLength) {
      return {
        code: 'name-too-large',
        message: `Le nom du PDF est plus grand que ${maxLength} caractères`,
      };
    }
    const megabytes = bytesToMegabytes(file.size);
    if (megabytes > maxSizeMo) {
      return {
        code: 'size-too-big',
        message: `Le poids du PDF est supérieur à ${maxSizeMo} Mo.`,
      };
    }
    return null;
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(file);
      setIsButtonEnabled(true);
    },
    [setIsButtonEnabled],
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': [],
    },
    validator: validatorDropzone,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {bytesToMegabytes(file.size)} Mo.
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  const handleLinkClick = async (typeFile) => {
    let fileURL = null;
    if (typeFile instanceof File) {
      fileURL = URL.createObjectURL(typeFile);
      window.open(fileURL, '_blank');
      URL.revokeObjectURL(fileURL);
    } else {
      if (process.env.REACT_APP_ENV === 'LOCAL') {
        fileURL = datasEdl.metadatas.mediaLink;
      } else {
        const storageRef = ref(storage, typeFile);
        fileURL = await getDownloadURL(storageRef);
      }
      window.open(fileURL, '_blank');
    }
  };

  return (
    <>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Glissez-déposez ou cliquez ici pour déposer votre rapport EDL</p>
      </div>
      {(file !== null || datasEdl.metadatas.isReportUpload) && (
        <div>
          <TextField
            sx={{ marginTop: '20px' }}
            fullWidth
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PictureAsPdfIcon color="primary" />
                  <span
                    style={{
                      cursor: 'pointer',
                      color: theme.palette.primary.main,
                      marginLeft: '20px',
                    }}
                    onClick={() => handleLinkClick(file ?? datasEdl.metadatas.pathReportEdl)}
                    className="hoverable-link"
                  >
                    {file?.name ?? datasEdl.metadatas.nameReportOrigin}
                  </span>
                </InputAdornment>
              ),
            }}
            variant="filled"
            disabled
          />
          <LinearProgress variant="determinate" value={uploadProgress} style={{ marginTop: '20px' }} />
        </div>
      )}
      <aside>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </>
  );
};
